<template>
  <div class="d-flex align-items-center justify-content-center">
    <div
      class="d-flex"
      :class="[$style[`prevPage`], {[$style[`disabled`]]: currentPage === 1}]"
      @click="setPrevPage"
    >
      <Icon
        name="arrow-left"
        :class="$style[`icon`]"
      />
    </div>
    <div
      v-for="page in pageList"
      :key="page"
      :class="[$style[`page`], {[$style[`currentPage`]]: page+1 === currentPage}]"
      @click="setPage(page+1)"
    >
      {{ page+1 }}
    </div>
    <div
      class="d-flex"
      :class="[$style[`nextPage`], {[$style[`disabled`]]: currentPage === totalPage}]"
      @click="setNextPage"
    >
      <Icon
        name="arrow-right"
        :class="$style[`icon`]"
      />
    </div>
  </div>
</template>
<script>
import { toRef, computed } from 'vue'
export default {
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPage: {
      type: Number,
      default: 1
    }
  },
  emits: ['setPage'],
  setup (props, { emit }) {
    const currentPage = toRef(props, 'currentPage')
    const totalPage = toRef(props, 'totalPage')

    const pageList = computed(() => {
      const totalPageList = [...Array(totalPage.value).keys()]
      const start = currentPage.value - 2 >= 0 ? currentPage.value - 2 : 0
      const end = currentPage.value + 2
      return totalPageList.slice(start, end)
    })

    const setPage = (page) => {
      if (page !== currentPage.value) {
        emit('setPage', page)
      }
    }

    const setPrevPage = () => {
      if (currentPage.value !== 1) {
        setPage(currentPage.value - 1)
      }
    }

    const setNextPage = () => {
      if (currentPage.value !== totalPage.value) {
        setPage(currentPage.value + 1)
      }
    }

    return { pageList, setPrevPage, setNextPage, setPage }
  }
}
</script>
<style lang="scss" module>
$width: 2rem;
$height: 2rem;
$borderRadius: 6px;

.icon {
  fill: $primary;
  width: $width;
  height: $height;
}

.page {
  width: $width;
  height: $height;
  color: $gray-400;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  user-select: none;
  border-radius: $borderRadius;
}

.page.currentPage {
  background-color: $primary;
  color: $white;
  cursor: unset;
}

.prevPage,
.nextPage {
  border: solid 1px $gray-400;
  border-radius: $borderRadius;
  cursor: pointer;
  margin: 0 0.8rem;
}

.prevPage.disabled,
.nextPage.disabled {
  cursor: unset;
  opacity: 0.5;

  .icon {
    fill: $gray-400;
  }

}
</style>
