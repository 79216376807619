<template>
  <div class="container">
    <div class="container-lg">
      <div class="row gx-5 gy-3">
        <!-- navbar -->
        <suspense>
          <template #default>
            <aside class="col-12 col-xxl-3">
              <ProductListAside />
            </aside>
          </template>
          <template #fallback>
            <div />
          </template>
        </suspense>

        <section class="col-12 col-xxl-9">
          <!-- 顯示商品選項 -->
          <div class="container-fluid g-0">
            <div class="row justify-content-between text-center gy-3">
              <div class="col-6 position-relative">
                <input
                  v-model="searchParams.queryString"
                  type="text"
                  class="form-control rounded-2"
                  placeholder="找商品"
                  @keydown.enter="readProductList"
                >
                <Icon
                  role="button"
                  name="search"
                  class="position-absolute top-50 end-0 translate-middle me-3"
                  :class="$style[`icon`]"
                  @click="readProductList"
                />
              </div>
              <div class="col-6">
                <!-- 商品排序 -->
                <select
                  v-model="searchParams.sort"
                  required
                  class="col-6 form-select rounded-2"
                  @change="readProductList"
                >
                  <option
                    :value="null"
                    selected
                  >
                    商品排序
                  </option>
                  <option
                    v-for="(sort, index) in sortList"
                    :key="index"
                    :value="sort.sortBy"
                  >
                    {{ sort.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- title -->
          <h4 class="title-border-bottom d-flex justify-content-between">
            <!-- <div>
              <span v-if="searchResultParams?.catMain && searchResultParams?.catMain !== ''">
                {{ searchResultParams.catMain }}
                <span v-show="searchResultParams?.catSub !== ''">/ {{ searchResultParams.catSub }}</span>
              </span>
              <span v-else>全部商品</span>
              <span
                v-if="searchResultParams?.queryString"
                :class="[$style[`transition-opacity`], {'opacity-0': isLoading}]"
              > / {{ searchResultParams.queryString }} 的搜尋結果</span>
            </div> -->
          </h4>

          <!-- empty msg -->
          <div
            v-if="productList.length === 0 && !isLoading"
            class="col-12 text-center h4 py-4"
          >
            Oops！這一系列還沒有商品喔！
          </div>

          <!-- product list -->
          <div
            class="container-fluid py-4 g-0"
            :class="{ rendered: productList.length !== 0 }"
          >
            <div class="row py-0">
              <div
                v-for="(product, index) in productList"
                :key="index"
                class="col-12 col-md-6 col-xxl-4 mb-3"
              >
                <router-link
                  class="position-relative d-block text-dark p-2 rounded-2"
                  :class="$style[`productWrap`]"
                  :to="`/product/detail/${product.productID}`"
                >
                  <!-- <div class="product-tag"
                :class="$style[`product-tag`]">HOT</div>
                                    <div class="product-tag"
                :class="$style[`product-tag`]">SALE</div> -->
                  <div
                    class="mb-2"
                    :class="$style[`productImg`]"
                    :style="[
                      product.mainImg
                        ? { backgroundImage: `url(${product.mainImg})` }
                        : { backgroundImage: `url('@/assets/img/nopic.gif')` }
                    ]"
                  />
                  <!-- <div
                    v-if="product.hasCw"
                    class="position-absolute start-0 top-0 bg-primary text-white px-3 py-1"
                  >
                    現貨
                  </div> -->
                  <div class="ps-1">
                    <div class="mb-1">
                      {{ product.name }}
                    </div>
                    <div
                      v-if="product.minPrice == product.maxPrice"
                      class="text-primary"
                    >
                      NTD$ {{ product.minPrice }}
                    </div>
                    <div
                      v-else
                      class="text-primary"
                    >
                      NTD$ {{ product.minPrice }}-{{ product.maxPrice }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <!-- page nav -->
          <PagePanigation
            v-show="!isLoading"
            class="mb-2"
            :current-page="page?.current"
            :total-page="page?.totalPage"
            @setPage="readProductList"
          />
          <!-- <span
            class="small pr-2"
          >{{ page.offset * pageSize }} - {{ page.current * pageSize }} 項商品 / 共 {{ page.totalPage }} 頁</span> -->
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, watch, provide, inject } from 'vue'
import { useStore } from 'vuex'
import ProductListAside from '@/components/ProductListAside'
import PagePanigation from '@/components/PagePanigation'
import { useRouter, useRoute } from 'vue-router'
export default {
  name: 'ProductList',
  components: {
    ProductListAside,
    PagePanigation
  },
  props: {
    queryString: {
      type: String,
      default: ''
    },
    categoryID: {
      type: String,
      default: ''
    },
    catMainID: {
      type: String,
      default: ''
    },
    catSubID: {
      type: String,
      default: ''
    }
  },
  async setup (props) {
    const { dispatch, getters } = useStore()
    const { push } = useRouter()
    const route = useRoute()

    const isLoading = computed(() => getters.getIsLoading)
    const categoryList = computed(() => getters['product/getCategoryList'])
    const productList = computed(() => getters['product/getProductList'])

    const setAlert = inject('setAlert')

    push({ path: '/product/list', replace: true })

    const pageSize = ref(20)
    const page = ref({
      totalElement: 0,
      current: 1,
      offset: 1,
      totalPage: 1
    })
    const initSet = ref({
      // 初始查詢設定
      begin: 1,
      end: 20
    })
    const searchParams = ref({
      queryString: props?.queryString.value || null,
      isActive: null,
      offset: '',
      pageSize: '',
      catMainID: props?.catMainID.value || null,
      catMain: null,
      catSubID: props?.catSubID.value || null,
      catSub: null,
      sort: null
    })
    const searchResultParams = ref({
      queryString: '',
      isActive: null,
      offset: '',
      pageSize: ''
    })

    const sortList = [
      // {
      //   sortBy: 'desc',
      //   sortColmn: 'maxPrice',
      //   text: '價格(由高至低)'
      // },
      // {
      //   sortBy: 'asc',
      //   sortColmn: 'maxPrice',
      //   text: '價格(由低至高)'
      // },
      {
        sortBy: 'desc',
        sortColmn: 'insertTime',
        text: '上架時間(由新至舊)'
      },
      {
        sortBy: 'asc',
        sortColmn: 'updateTime',
        text: '上架時間(由舊至新)'
      }
    ]

    provide('categoryList', categoryList)
    provide('searchParams', searchParams)

    const clearSelectedSubCategory = () => {
      searchParams.value.catSubID = null
      searchParams.value.catSub = null
    }

    const selectedCategory = computed(() => {
      if (window.document.activeElement.tagName === 'SELECT') {
        clearSelectedSubCategory()
      }
      return (
        categoryList.value.find(
          (el) => el.catMainID === searchParams.value.catMainID
        ) || {}
      )
    })
    provide('selectedCategory', selectedCategory)

    const setSearchResultParams = () => {
      searchResultParams.value = JSON.parse(JSON.stringify(searchParams.value))
      if (categoryList.value.length > 0) {
        const catMainItem = searchResultParams.value.catMainID
          ? categoryList.value.find(
            (el) => el.catMainID === searchResultParams.value.catMainID
          )
          : undefined
        searchResultParams.value.catMain =
          catMainItem && catMainItem?.catMain ? catMainItem?.catMain : ''

        const catSubItem =
          selectedCategory.value.subCategoryList &&
          searchResultParams.value.catSubID
            ? selectedCategory.value.subCategoryList.find(
              (el) => el.catSubID === searchResultParams.value.catSubID
            )
            : undefined
        searchResultParams.value.catSub =
          catSubItem && catSubItem?.catSub ? catSubItem?.catSub : ''
      }
    }

    const readProductList = async (pageNumber) => {
      setSearchResultParams()
      page.value.totalElement = 0
      if (typeof pageNumber === 'object' || !pageNumber) {
        pageNumber = 1
      }
      const payload = {}
      for (const [key, value] of Object.entries(searchParams.value)) {
        if (value !== null && value !== '') {
          if (key === 'sort') {
            payload['sort[0].sortBy'] = value
            payload['sort[0].sortColmn'] = 'insertTime'
          } else if (key === 'queryString') {
            payload.name = value
            payload[key] = value
          } else {
            payload[key] = value
          }
        }
      }
      payload.offset = (pageNumber - 1) * pageSize.value
      payload.pageSize = pageSize.value
      payload.isActive = true
      payload.needSpec1AndSpec2AndSpecPrice = false

      initSet.value.begin = 1
      page.value.current = pageNumber || 1
      try {
        const response = await dispatch('product/readProductList', { payload })
        page.value.offset = response.data.data?.page?.offset || 0
        page.value.totalElement = response.data.data?.page?.totalElement || 0
        page.value.totalPage = response.data.data?.page?.totalPage || 1
      } catch (error) {
        console.log(error)
        page.value.totalElement = 0
      }
    }

    const searchSpecificCategory = (mainID, subID) => {
      if (mainID && subID) {
        searchParams.value.catMainID = mainID
        searchParams.value.catSubID = subID
      } else {
        // all product without category filter
        resetSearchParams()
      }
      readProductList()
    }

    const resetSearchParams = () => {
      searchParams.value = {
        queryString: '',
        isActive: null,
        offset: '',
        pageSize: '',
        catMainID: null,
        catSubID: null
      }
    }

    const getCategoryList = async () => {
      const payload = {}

      try {
        await dispatch('product/readCategoryList', { payload })
      } catch (error) {
        console.log(error)
        setAlert(true, false, error)
      }
    }

    watch(
      route,
      async () => {
        // not redirect
        if (route.path === '/product/list') {
          if (Object.keys(route.query).length !== 0) {
            const queryString = route.query?.queryString || null
            let catMainID = route.query?.catMainID || null
            let catSubID = route.query?.catSubID || null
            const CategoryID = route.query?.categoryID
            if (route.query?.categoryID && categoryList.value.length > 0) {
              categoryList.value.find((category) => {
                if (category.catMainID === CategoryID) {
                  catMainID = category.catMainID
                  return false
                }
                category.subCategoryList.find((subCate) => {
                  if (subCate.catSubID === CategoryID) {
                    catMainID = category.catMainID
                    catSubID = subCate.catSubID
                    return false
                  }
                })
              })
            }

            searchParams.value.queryString = queryString
            searchParams.value.catMainID = catMainID
            searchParams.value.catSubID = catSubID
            push({ path: '/product/list', replace: true })
          } else {
            await readProductList(1)
          }
        }
      },
      { immediate: true }
    )

    provide('readProductList', readProductList)
    provide('searchSpecificCategory', searchSpecificCategory)
    provide('resetSearchParams', resetSearchParams)

    if (categoryList.value.length === 0) {
      await getCategoryList()
    }

    return {
      sortList,
      isLoading,
      categoryList,
      searchParams,
      searchResultParams,
      resetSearchParams,
      readProductList,
      productList,
      selectedCategory,
      searchSpecificCategory,
      page,
      pageSize
    }
  }
}
</script>
<style lang="scss" module>
.icon {
  padding: 0.5rem;
  fill: $primary;
  height: 2.125rem;
  width: 2.125rem;
}

.object-fit-cover {
  object-fit: cover;
}

.transition-opacity {
  transition: opacity 0.3s;
}

/* product */
.productWrap {
  overflow: hidden;
  text-decoration: none;
  transition: box-shadow 0.3s;
}

.productWrap:hover {
  box-shadow: 0px 0px 8px 0px rgba($black, 0.24);
}

.product-tag {
  background-color: rgb(var(--pantone-color));
  color: $white;
  font-weight: bold;
  left: 0.5rem;
  padding: 0.75rem 1.25rem;
  position: absolute;
  top: 0;
}

.productImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 150%;
}
</style>
