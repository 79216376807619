<template>
  <div
    :class="{'rendered': categoryList}"
  >
    <!-- 所有商品 -->
    <div
      :class="$style[`dropdown`]"
    >
      <div
        class="fw-bold text-primary text-decoration-none border-bottom border-primary"
        :class="$style[`dropdownItemTitle`]"
        role="button"
        @click="searchSpecificCategory"
      >
        所有商品
      </div>
    </div>
    <div
      v-for="(category, index) in categoryList"
      :key="index"
      class="position-relative"
    >
      <div
        :class="[$style[`dropdown`],
                 {[$style[`active`]]: showedCategoryId === category.catMainID}]"
        @click="category.subCategoryList.length>1 ? setShowedCategoryId(category.catMainID) : null"
      >
        <div
          class="d-flex justify-content-between align-items-center border-bottom fw-bold"
          :class="[$style[`dropdownItemTitle`], {'border-primary': showedCategoryId === category.catMainID}]"
          role="button"
          @click="category.subCategoryList.length>1 ? null : push('/product/list?catMainID='+category.catMainID)"
        >
          {{ category.catMain }}
          <!-- <img
              v-if="category.subCategoryList.length>1"
              data-src="@/assets/img/next.svg"
              class="svg category-navbar-svg"
            > -->
          <Icon
            :class="[$style.icon, $style[`dropdownArrow`]]"
            name="arrow-down"
          />
        </div>

        <!-- subCategoryList -->
        <div
          v-if="category.subCategoryList.length>1"
          :class="$style[`dropdownMenu`]"
        >
          <a
            v-for="sub in category.subCategoryList"
            :key="sub.catSubID"
            role="button"
            :class="[$style[`dropdownItem`], {'d-none': sub.catSubID==='none'}]"
            @click="searchSpecificCategory(sub.catMainID, sub.catSubID)"
          >{{ sub.catSub }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  setup () {
    const { push } = useRouter()

    const categoryList = inject('categoryList')
    const selectedCategory = inject('selectedCategory')
    const readProductList = inject('readProductList')
    const searchSpecificCategory = inject('searchSpecificCategory')

    const showedCategoryId = ref('')
    const setShowedCategoryId = (id) => {
      if (showedCategoryId.value === id) {
        showedCategoryId.value = ''
      } else {
        showedCategoryId.value = id
      }
    }

    return {
      push,
      categoryList,
      selectedCategory,
      readProductList,
      showedCategoryId,
      setShowedCategoryId,
      searchSpecificCategory
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 0.9rem;
  width: 0.9rem;
}

.dropdown {
  .dropdownArrow {
    height: 1.5rem;
    width: 1.5rem;
    transition: transform 0.3s, fill 0.3s;
  }

  .dropdownMenu {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    text-align: left;
    transition: max-height 0.3s;
  }

  .dropdownItem {
    color: $dark;
    padding: 1.5rem 1rem;
    text-decoration: none;
    transition: color .3s;
  }

  .dropdownItem:hover {
    color: $primary;
  }

  .dropdownItemTitle {
    color: $dark;
    padding: 1.5rem 1rem;
    text-align: left;
    text-decoration: none;
  }

  &.active {
    .dropdownItemTitle{
      color: $primary;
    }

    .dropdownMenu {
      max-height: 100vh;
    }

    .dropdownArrow {
      fill: $primary;
      transform: rotate(-180deg);
    }
  }
}

</style>
